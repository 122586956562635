"use client";
import React, { useEffect, useRef, useState } from "react";
import { cx } from "class-variance-authority";
import { createPortal } from "react-dom";

export const dynamic = "force-dynamic";

const Button = ({ children, className }) => {
    const [popup, setPopUp] = useState(false);
    const backdropRef = useRef(null);
    const Ref = useRef();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (popup) {
                window.Calendly.initInlineWidget({
                    url: "https://calendly.com/business-tanthetaa/discuss",
                    parentElement:
                        document.getElementById("calendly-container"),
                    prefill: {},
                });
            }
        };

        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, [popup]);

    useEffect(() => {
        function handleClick(e) {
            if (Ref.current && !Ref.current.contains(e.target)) {
                setPopUp(false);
            }
        }

        document.addEventListener("click", handleClick, true);

        return () => document.removeEventListener("click", handleClick, true);
    }, [popup, Ref]);

    return (
        <>
            <button
                className={cx(
                    "transition-colors antialiased delay-150 duration-500 ease-in-out",
                    className
                )}
                onClick={() => setPopUp(!popup)}
            >
                {children && children}
            </button>

            {popup && (
                <div>
                    {createPortal(
                        <>
                            <div
                                ref={backdropRef}
                                className="fixed inset-0 bg-gray-800 bg-opacity-50 backdrop-blur-sm !overflow-hidden z-40"
                            ></div>
                            <div className="fixed inset-0 flex items-center justify-center z-50">
                                <div
                                    id="calendly-container"
                                    className="calendlyStyle"
                                    ref={Ref}
                                    style={{
                                        overflow: "hidden",
                                        backgroundColor: "white",
                                        borderRadius: "8px",
                                        padding: "20px",
                                        zIndex: 50,
                                    }}
                                ></div>
                            </div>
                        </>,
                        document.body
                    )}
                </div>
            )}
        </>
    );
};

export default Button;
